@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:300,400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ECEFF1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body, html {
  font-family: 'Roboto Slab', serif;
  box-sizing: border-box;
}
.App {
  text-align: center;
  background-color: #ECEFF1;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

input, textarea {
  border: 1px solid #e0e0e0;
  padding: 10px;
  font-size: 20px;
  background-color: #fff;
  border-radius: 4px;
}

button {
  padding: 8px;
  background-color: #2196F3;
  border: 1px solid #1976D2;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
}
